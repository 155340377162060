import {Box, Button, Card, CloseButton, Flex, Heading, Spacer} from "@chakra-ui/react";
import React, {useEffect, useRef, useState, useCallback} from "react";

export function PopupButton(props) {
  return <Button onClick={props.onClick} isDisabled={props.disabled} colorScheme='blue' size="sm" ml={2}>
    {props.children}
  </Button>
}

export function PopupLayout({maxHeight, pageX, pageY, title, closeFunction, minWidth, minHeight, overflow, ...props}) {
  const componentRef = useRef(null);
  const handleRef = useRef(null);
  const [componentSize, setComponentSize] = useState({ width: 0, height: 0 });
  const [handleHeight, setHandleHeight] = useState(0);
  const [isDragging, setIsDragging] = useState(false);
  const [zIndex, setZIndex] = useState(1000);
  const [position, setPosition] = useState({
    x: pageX,
    y: pageY
  });
  const dragStartRef = useRef({ x: 0, y: 0, offsetX: 0, offsetY: 0 });

  const updatePosition = useCallback((x, y) => {
    const newPosition = {
      x: Math.max(Math.min(x, window.innerWidth - componentSize.width), 0),
      y: Math.max(Math.min(y, window.innerHeight - handleHeight), 0)
    };
    setPosition(newPosition);
    window.localStorage.setItem(title, JSON.stringify(newPosition));
  }, [componentSize.width, handleHeight, title]);

  useEffect(() => {
    const prev = window.localStorage.getItem(title);
    if (prev) {
      setPosition(JSON.parse(prev));
    }
    
    const handleResize = () => {
      if (componentRef.current && handleRef.current) {
        const componentRect = componentRef.current.getBoundingClientRect();
        const handleRect = handleRef.current.getBoundingClientRect();
        setComponentSize({ width: componentRect.width, height: componentRect.height });
        setHandleHeight(handleRect.height);
        
        setPosition(prevPosition => {
          const newPosition = {
            x: Math.min(prevPosition.x, window.innerWidth - componentRect.width),
            y: Math.min(prevPosition.y, window.innerHeight - handleRect.height)
          };
          window.localStorage.setItem(title, JSON.stringify(newPosition));
          return newPosition;
        });
      }
    };

    window.addEventListener('resize', handleResize);
    handleResize(); // Call once to set initial size

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [title, updatePosition]);

  useEffect(() => {
    const handleMouseMove = (event) => {
      if (isDragging) {
        event.preventDefault();
        const newX = event.clientX - dragStartRef.current.offsetX;
        const newY = event.clientY - dragStartRef.current.offsetY;
        updatePosition(newX, newY);
      }
    };

    const handleMouseUp = () => {
      setIsDragging(false);
    };

    if (isDragging) {
      document.addEventListener('mousemove', handleMouseMove);
      document.addEventListener('mouseup', handleMouseUp);
    }

    return () => {
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('mouseup', handleMouseUp);
    };
  }, [isDragging, updatePosition]);

  const handleClose = () => {
    window.localStorage.removeItem(title);
    closeFunction();
  }

  const handleMouseDown = (event) => {
    if (event.target.closest('button, input, select, textarea, a')) {
      return;
    }
    event.preventDefault();
    const rect = componentRef.current.getBoundingClientRect();
    dragStartRef.current = {
      x: event.clientX,
      y: event.clientY,
      offsetX: event.clientX - rect.left,
      offsetY: event.clientY - rect.top
    };
    setIsDragging(true);
    setZIndex(prevZIndex => prevZIndex + 1);
  }

  return (
    <Card 
      position="fixed" 
      width={minWidth || '320px'} 
      height={minHeight || ''}
      zIndex={zIndex} 
      borderRadius="10px" 
      boxShadow="lg" 
      bgColor="white"
      top={position.y + "px"} 
      left={position.x + "px"} 
      overflow="hidden"
      ref={componentRef}
    >
      <Flex 
        ref={handleRef}
        onMouseDown={handleMouseDown}
        cursor={isDragging ? 'grabbing' : 'grab'}
        background="white" 
        width="100%" 
        zIndex={1}
        pt={4} 
        pb={3} 
        pl={4} 
        pr={2} 
        borderBottom='1px solid #eee'
        justifyContent="space-between" 
        alignItems="center"
      >
        <Heading size='sm'>{title}</Heading>
        <Spacer/>
        <CloseButton onClick={handleClose} size="sm"/>
      </Flex>
      <Box
        overflow="auto"
        maxHeight={maxHeight}
        css={{
          '&::-webkit-scrollbar': {
            width: '8px',
          },
          '&::-webkit-scrollbar-track': {
            width: '10px',
          },
          '&::-webkit-scrollbar-thumb': {
            background: '#888',
            borderRadius: '24px',
          },
        }}
      >
        <Box 
          p={2}
          overflowY="auto"
          overflowX="hidden"
          maxHeight={`calc(100vh - ${handleHeight}px - 16px)`} // Subtract handle height and some padding
        >
          {props.children}
        </Box>
        {props.buttons && (
          <Flex 
            mt={4} 
            pt={4} 
            pb={4} 
            pl={4} 
            pr={2} 
            borderTop='1px solid #eee'
            alignItems="center" 
            justifyContent="flex-end"
          >
            {props.buttons}
          </Flex>
        )}
      </Box>
    </Card>
  );
}