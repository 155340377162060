import {Box, Button, Card, Flex, Switch, Tooltip} from "@chakra-ui/react";
import { useEffect, useRef, useState } from "react";
import {FaArrowLeft, FaDashcube, FaHandPointDown, FaHandPointUp, FaHome, FaPrint, FaRedo, FaRegSave, FaSave, FaSquare, FaUndo} from "react-icons/fa";
import {
    FaArrowPointer,
    FaDownload,
    FaHand,
    FaMagnifyingGlassMinus,
    FaMagnifyingGlassPlus,
    FaUpload,
    FaWandMagicSparkles,
    FaArrowsToCircle  // New import for reset zoom icon
} from "react-icons/fa6";
import {TbZoomReset} from "react-icons/tb";
import {useNavigate, useParams} from "react-router-dom";

export function DesignerToolbar(props) {
    const navigate = useNavigate();
    const {dataId} = useParams();
    const toolRef = useRef(null);
    const [isDragging, setIsDragging] = useState(false);
    const [update, setUpdate] = useState(false);
    const [position, setPosition] = useState({
        x: 0,
        y: 0
    });
    const [previousMousePosition, setPreviousMousePosition] = useState({x: 0, y: 0});
    let componentBoundingBox = toolRef.current ? toolRef.current.getBoundingClientRect() : null;
    let componentWidth = componentBoundingBox ? componentBoundingBox.width : 0;
    let componentHeight = componentBoundingBox ? componentBoundingBox.height : 0;

    useEffect(() => {
        if (isDragging) {
            document.addEventListener('mousemove', handleMouseMove);
            document.addEventListener('mouseup', handleMouseUp);
        }

        return () => {
            document.removeEventListener('mousemove', handleMouseMove);
            document.removeEventListener('mouseup', handleMouseUp);
        }
    }, [isDragging])

    const handleMouseUp = () => {
        setIsDragging(false);
    }

    const handleMouseMove = (event) => {
        event.preventDefault();
        if (isDragging) {
            const newPosition = {
                x: Math.min(Math.max(position.x + (event.clientX - previousMousePosition.x), 0), window.innerWidth - componentWidth*1.5),
                y: Math.min(Math.max(position.y + (event.clientY - previousMousePosition.y), 0), window.innerHeight - componentHeight*1.5)
            };

            setPosition(newPosition);
            setPreviousMousePosition({x: event.clientX, y: event.clientY});
        }
    }

    const handleMouseDown = (event) => {
        event.preventDefault();
        setPreviousMousePosition({x: event.clientX, y: event.clientY});
        setIsDragging(true);

        //TODO: Calculate highest zIndex and update zIndex so popup comes on top when clicked
    }

    const handleResetZoom = () => {
        if (props.paperScroller) {
            props.paperScroller.zoomToRect({ x: 0, y: 0, width: 1587, height: 1123 })
        }
    };


    return <Card onMouseDown={handleMouseDown} left={position.x + "px"} top={position.y+"px"} ref={toolRef}><Flex  direction="row"
                 p={4} pl={2} pr={2}
                 background="white"
                 boxShadow="md"
                 borderRadius={8}
                 cursor="grab">
        <Tooltip label="Home" aria-label="Home">
        <Button variant="link" onClick={() => props.toolbarService.toolbar.trigger('home:pointerclick')}>
            <FaHome/>
        </Button>
        </Tooltip>
        <Box ml={1} mr={1}>|</Box>

        <Tooltip label="Save" aria-label="Save">
        <Button variant="link" onClick={() => {
            if (props.toolbarService) {
                props.toolbarService.toolbar.trigger('save-json:pointerclick');
            }
        }}>
            <FaSave/>
        </Button>
        </Tooltip>
        <Tooltip label="Save As" aria-label="Save As">
        <Button variant="link" onClick={() => {
            if (props.toolbarService) {
                props.toolbarService.toolbar.trigger('save-as:pointerclick');
            }
        }}>
            <FaRegSave/>
        </Button>
        </Tooltip>
        <Tooltip label="Zoom In" aria-label="Zoom In">
        <Button variant="link" onClick={() => {
            if (props.paperScroller) {
                props.paperScroller.zoom(0.1);
            }
        }
        }>
            <FaMagnifyingGlassPlus/>
        </Button>
        </Tooltip>
        <Tooltip label="Zoom Out" aria-label="Zoom Out">
        <Button variant="link" onClick={() => {
            if (props.paperScroller) {
                props.paperScroller.zoom(-0.1);
            }
        }
        }>
            <FaMagnifyingGlassMinus/>
        </Button>
        </Tooltip>
        <Tooltip label="Reset Zoom" aria-label="Reset Zoom">
        <Button variant="link" onClick={handleResetZoom}>
            <TbZoomReset size={19}/>
        </Button>
        </Tooltip>
        <Tooltip label="Download" aria-label="Download">
        <Button variant="link" onClick={() => {
            if (props.toolbarService) {
                props.toolbarService.toolbar.trigger('download:pointerclick');
            }
        }
        }>
            <FaDownload/>
        </Button>
        </Tooltip>
        <Tooltip label="Upload" aria-label="Upload">
        <Button variant="link" onClick={() => {
            if (props.toolbarService) {
                props.toolbarService.toolbar.trigger('upload:pointerclick');
            }
        }
        }>
            <FaUpload/>
        </Button>
        </Tooltip>
        <Tooltip label="Print" aria-label="Print">
        <Button variant="link" onClick={() => {
            if (props.toolbarService) {
                props.toolbarService.toolbar.trigger('print:pointerclick');
            }
        }
        }>
            <FaPrint/>
        </Button>
        </Tooltip>

        <Box ml={1} mr={3}>|</Box>

        <Tooltip label="Switch To Simulator" aria-label="Switch To Simulator">
        <Box position="relative" onClick={() => {
            if (props.toolbarService) {
                props.toolbarService.toolbar.trigger('switch-simulator:pointerclick');
            }
        }}>
            <Switch colorScheme='teal' size='lg'/>
            <Box position="absolute" top={1.5} left={2}>
                <FaWandMagicSparkles/>
            </Box>
        </Box>
        </Tooltip>

        <Box ml={1} mr={3}>|</Box>
        <Tooltip label="Pan" aria-label="Pan">
        <Button variant={(props.toolbarService.selectedTool == "pan" ? "outline" : props.toolbarService.selectedTool == "select" ? "link" : "link")} size="sm" onClick={() => {
            if (props.toolbarService) {
                props.toolbarService.selectedTool = 'pan';
                props.toolbarService.toolbar.trigger('tool:select');
                setUpdate(!update);
            }
        }}>
            <FaHand/>
        </Button>
        </Tooltip>
        <Tooltip label="Select" aria-label="Select">
        <Button variant={(props.toolbarService.selectedTool == "pan" ? "link" : props.toolbarService.selectedTool == "select" ? "outline" : "link")} size="sm" onClick={() => {
            if (props.toolbarService) {
                props.toolbarService.selectedTool = 'select';
                props.toolbarService.toolbar.trigger('tool:select');
                setUpdate(!update);
            }
        }}>
            <FaArrowPointer/>
        </Button>
        </Tooltip>
        
    </Flex>
    </Card>
}
